import React from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { Tag, Button } from "antd";
import {
    DeleteOutlined,
    PlusSquareOutlined,
    WarningOutlined,
    FullscreenExitOutlined,
    FileSearchOutlined,
} from "@ant-design/icons";
import format from "@app/lib/format";
import { StarFilled, ClockCircleOutlined } from "@ant-design/icons";
import Card from "@app/components/card/card";
import FiltersSummary from "@app/components/search/summary";
import Source from "./source";
import Type from "./type";
import Tags from "@app/components/tags/tags";
import Progress from "./progress";
import { SearchCategory, SearchStatus } from "@app/constants";
import CommentsTag from "@app/components/comments/chunks/comments-tag";

import "./style/card.scoped.scss";

const SearchCard = observer(
    ({ search, className, onRemove, readonly = false, showRibbon = true, ...rest }) => {
        /**
         * Handle delete button click
         * @param {MouseEvent} event
         */
        const remove = (event) => {
            event.preventDefault();
            if (onRemove) {
                onRemove(search, event);
            }
        };

        const classes = classNames("search-card", className, {
            saved: search.status === SearchStatus.SAVED || search.status === SearchStatus.SAVING,
            saving: search.status === SearchStatus.SAVING,
            hidden: search.status === SearchStatus.HIDDEN,
        });

        const getSavedLabel = () => {
            switch (search.status) {
                case SearchStatus.SAVED:
                    return "Saved";
                case SearchStatus.SAVING:
                    return "Saving...";
                default:
                    return "";
            }
        };

        const getStatusRibbon = () => {
            if (search.status === SearchStatus.SAVED || search.status === SearchStatus.SAVING) {
                return (
                    <div className="tag saved">
                        <StarFilled className="star" /> <span>{getSavedLabel()}</span>
                    </div>
                );
            } else {
                return (
                    <div className="tag status">
                        <ClockCircleOutlined className="circle" />
                        <span>Pending</span>
                    </div>
                );
            }
        };

        return (
            <Card className={classes} {...rest}>
                <div className="count">
                    {showRibbon && getStatusRibbon()}
                    <div className="results">
                        <em>{format.number(search.results)}</em>
                        <label>results</label>
                    </div>
                </div>
                <div className="details">
                    <div className="name">{search.name}</div>
                    <div className="query">{search.query}</div>
                    <FiltersSummary search={search} />
                    <div className="time">
                        <span>Performed: {format.date(search.performedOn)}, </span>
                        <span>Created: {format.datetime(search.created)}</span>
                    </div>

                    <div className="tags">
                        <Tag className="tag type-tag">
                            <Type value={search.type} />
                        </Tag>

                        {search.source && (
                            <Tag className="tag source-tag">
                                <Source name={search.source} />
                            </Tag>
                        )}

                        {search.category === SearchCategory.SYSTEMATIC_SEARCH && (
                            <Tag className="tag category">
                                <FileSearchOutlined /> {SearchCategory.SYSTEMATIC_SEARCH}
                            </Tag>
                        )}

                        {search.category === SearchCategory.GREY_LITERATURE && (
                            <Tag className="tag category">
                                <PlusSquareOutlined /> {SearchCategory.GREY_LITERATURE}
                            </Tag>
                        )}

                        {search.category === SearchCategory.FOCUSED_SEARCH && (
                            <Tag className="tag category">
                                <FullscreenExitOutlined /> {SearchCategory.FOCUSED_SEARCH}
                            </Tag>
                        )}

                        {search.logs?.length > 0 && (
                            <div className="warning">
                                <WarningOutlined />
                            </div>
                        )}

                        {search.totalComments > 0 && <CommentsTag count={search.totalComments} />}
                    </div>

                    <Tags assignedTags={search.tags} />
                </div>
                <div className="progress">
                    <Progress value={search.progress} width={40} />
                </div>
                <div className="actions">
                    {[SearchStatus.SAVED, SearchStatus.DRAFT].includes(search.status) &&
                        !readonly && (
                            <Button
                                onClick={remove}
                                type="text"
                                danger
                                icon={<DeleteOutlined />}
                            ></Button>
                        )}
                </div>
            </Card>
        );
    },
);

export default SearchCard;
