import React, { useEffect } from "react";
import { Form, Input } from "antd";
import { observer } from "mobx-react";

import { default as Modal } from "@app/components/modal";
import notify from "@app/components/notify";

const AddVariantModal = observer(
    ({ visible, isEditing, onCancel, onSubmit, variants = [], variantId }) => {
        const [form] = Form.useForm();

        useEffect(() => {
            if (!isEditing) {
                form.setFieldsValue({ title: "" });
            }

            const variant = variants.find((v) => v._id === variantId);

            if (variant?.title) {
                form.setFieldsValue({ title: variant.title });
            } else {
                form.setFieldsValue({ title: "" });
            }
        }, [variantId, variants, form, isEditing]);

        const error = () => {
            notify.error("Please fill all required fields to continue");
        };

        const save = (data) => {
            onSubmit && onSubmit({ ...data, variantId });

            form.resetFields();
        };

        const onCloseModal = () => {
            form.resetFields();
            onCancel && onCancel();
        };

        const title = isEditing ? "Edit Variant" : "Add Variant";
        const okText = isEditing ? "Save" : "Add";

        return (
            <Modal
                title={title}
                visible={visible}
                onCancel={onCloseModal}
                onOk={form.submit.bind(form)}
                okText={okText}
            >
                <div>
                    <Form layout="vertical" form={form} onFinishFailed={error} onFinish={save}>
                        <Form.Item
                            label="Variant Name"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: "Please enter the name",
                                },
                                {
                                    validator: (_, value) => {
                                        if (!value) {
                                            return Promise.resolve();
                                        }
                                        const variantList = Array.isArray(variants) ? variants : [];
                                        const nameExists = variantList.some(
                                            (v) =>
                                                v?.title?.toLowerCase() ===
                                                    value.trim().toLowerCase() &&
                                                !(isEditing && (v._id === variantId || !v._id)),
                                        );

                                        if (nameExists) {
                                            return Promise.reject(
                                                new Error(
                                                    "A variant with this name already exists",
                                                ),
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    },
);

export default AddVariantModal;
