import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Page } from "@app/components/page";
import Filter from "@app/components/filter";
import EmptyList from "@app/components/list/empty";
import { useHistory } from "react-router-dom";

import Card from "@app/components/article/cards/l2";
import ArticleFilter from "@app/components/article/filter";

import l2 from "@app/state/store/report/l2";
import useHistoryFilters from "@app/components/hooks/useHistoryFilters";
import { events } from "@app/lib/store";
import { Button, Menu, Dropdown } from "antd";
import {
    FileExcelOutlined,
    FileSyncOutlined,
    DownOutlined,
    FileZipOutlined,
} from "@ant-design/icons";
import qs from "qs";

import reportStore from "@app/state/store/report";
import session from "@app/state/store/session";
import { Review } from "@app/constants";
import { MultiSelect, MultiAction } from "@app/components/multi-select";
import MultiActionModal from "@app/components/article/multi-action-modal";
import { useContext as useMultiSelectContext } from "@app/components/multi-select/context";
import articleActions from "@app/state/store/action/article";
import { BulkUploadMenuItem, BulkUploadProgress } from "@app/components/bulk-upload";

const Anchor = ({ exportUrl, text }) => (
    <a href={exportUrl} target="_blank" rel="noopener noreferrer">
        {text}
    </a>
);

const List = observer(({ className, ...rest }) => {
    const history = useHistory();
    const { article, type } = useParams();
    const config = reportStore.config;
    const project = reportStore.project;
    const readonly = !session.can("article.update") || reportStore.readOnly;

    const filter = l2.filter[type];
    useHistoryFilters(filter);

    const [multiActionModalOpen, setMultiActionModalOpen] = useState(false);

    events.removeAllListeners(["article.updateMany"]);
    events.on("article.updateMany", () => {
        // refresh list
        l2.load(type);
    });

    useEffect(() => {
        l2.article = article;
    }, [article]);

    // initialize the state when component mounts.
    useEffect(() => {
        l2.load(type);
        articleActions.setContext({ stage: Review.L2, type });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [l2.project, type]);

    /**
     * Focus an article
     */
    const focus = (id) => {
        l2.article = id;
    };

    // calculate the base url
    const baseUrl = `/r/${l2.project}/${type}/l2`;

    // Calculate Export URL
    let exportUrl = `/api/project/${l2.project}/articles/l2/export?`;

    const params = filter.value();
    params.type = type;

    const queryString = qs.stringify(params);
    exportUrl += queryString;

    /**
     * Prepare the export menu
     */
    const exportMenu = (
        <Menu className="menu">
            <Menu.Item key="bulk-upload">
                <BulkUploadMenuItem stage={Review.L2} type={type} />
            </Menu.Item>
            <Menu.Divider key="divider" className={"divider"} />
            <Menu.Item key="excel" icon={<FileExcelOutlined />}>
                <Anchor text="Export Excel" exportUrl={exportUrl + "&format=excel"} />
            </Menu.Item>
            <Menu.Item key="zotero" icon={<FileSyncOutlined />}>
                <Anchor text="Export RIS" exportUrl={exportUrl + "&format=zotero"} />
            </Menu.Item>
            <Menu.Item key="zip" icon={<FileZipOutlined />}>
                <Anchor text="Export RIS + Full Text" exportUrl={exportUrl + "&format=zip"} />
            </Menu.Item>
            <Menu.Item key="RIS + Full Text" icon={<FileZipOutlined />}>
                <Anchor
                    text="Export RIS + Full Text with Highlights"
                    exportUrl={exportUrl + "&format=zip&highlights=true"}
                />
            </Menu.Item>
        </Menu>
    );

    const MultiSelectWrapper = observer(() => {
        const { context } = useMultiSelectContext();
        const { selected, reset } = context;

        // disable the multi action dropdown
        if (readonly) {
            return <Filter.Header filter={filter} />;
        }

        return (
            <>
                <Filter.Header
                    filter={filter}
                    showMultiAction={true}
                    multiAction={<MultiAction handleUpdate={() => setMultiActionModalOpen(true)} />}
                    afterSearch={() => {
                        reset();
                    }}
                />
                <MultiActionModal
                    modalOpen={multiActionModalOpen}
                    setModalOpen={setMultiActionModalOpen}
                    articleIds={selected?.filter((item) => item.checked).map((item) => item._id)}
                    afterSubmit={() => reset()}
                    values={{ type }}
                />
            </>
        );
    });

    const sanitizeLocationSearch = (search) => {
        const { snippet, area, ...rest } = qs.parse(search, { ignoreQueryPrefix: true });
        return qs.stringify(rest);
    };

    return (
        <Page className={classNames("l2-list", className)}>
            <Page.Header>
                <Page.Header.Left shrink>
                    <Page.Title>L2 Review</Page.Title>
                </Page.Header.Left>
                <Page.Header.Right>
                    <BulkUploadProgress type={type} />

                    <Dropdown overlay={exportMenu} trigger="click" placement="bottomRight">
                        <Button>
                            Actions <DownOutlined />
                        </Button>
                    </Dropdown>
                </Page.Header.Right>
            </Page.Header>
            <MultiSelect totalList={l2.totalList}>
                <MultiSelectWrapper />
                <Page.Layout>
                    <Page.Body filter={filter}>
                        <EmptyList
                            title="No Articles Found"
                            show={l2.list.length === 0 && !l2.loading}
                        ></EmptyList>

                        {l2.list.map((article) => {
                            return (
                                <Link
                                    to={{
                                        pathname: `${baseUrl}/${article._id}`,
                                        search: sanitizeLocationSearch(history.location.search),
                                    }}
                                    onClick={() => focus(article._id)}
                                    key={article._id}
                                    style={{ width: "100%" }}
                                >
                                    <Card
                                        article={article}
                                        type={type}
                                        refStyle={config.reference?.style}
                                        keywords={config[type]?.keywords}
                                        focus={l2.article === article._id}
                                        readonly={readonly}
                                        checkedBox={!readonly}
                                    />
                                </Link>
                            );
                        })}
                    </Page.Body>

                    <ArticleFilter
                        filter={filter}
                        stage={Review.L2}
                        type={type}
                        config={reportStore.config}
                        project={project}
                        searches={l2.searches}
                    />
                </Page.Layout>
            </MultiSelect>
            <Page.Footer>
                <Filter.Pager filter={filter} />
            </Page.Footer>
        </Page>
    );
});

export default List;
