import React from "react";
import { observer } from "mobx-react";
import { UploadOutlined } from "@ant-design/icons";
import BulkUploadModal from "./bulk-upload-modal";
import { Button } from "antd";

import bulkUploadStore from "@app/state/store/report/bulk-upload";

/**
 * A menu item component for bulk uploading articles for due/sota l1 and l2
 * the upload is handled by single endpoint, after the upload starts the user should be able to navigate away from the modal
 * @component
 * @param {string} type - The type of the articles to upload (SLR or SOTA)
 * @returns {JSX.Element} Bulk upload menu item with modal
 */

const BulkUploadMenuItem = observer(({ type }) => {
    const onButtonClick = () => {
        bulkUploadStore.toggleModal(true, type);
    };

    return (
        <div className="upload">
            <Button type="text" onClick={onButtonClick}>
                <UploadOutlined /> Bulk Upload
            </Button>
            <BulkUploadModal />
        </div>
    );
});

export default BulkUploadMenuItem;
