import React from "react";
import { observer } from "mobx-react";
import { Button } from "antd";

import bulkUploadStore from "@app/state/store/report/bulk-upload";
import BulkUploadResultsModal from "./bulk-upload-results-modal";

const BulkUploadProgress = observer(({ type }) => {
    if (bulkUploadStore.getProgress(type) === 100 && !bulkUploadStore.getIsLoading(type)) {
        return (
            <div>
                <Button onClick={bulkUploadStore.toggleResultsModal}>
                    Upload complete. Show Results
                </Button>
                <BulkUploadResultsModal />
            </div>
        );
    }

    if (!bulkUploadStore.getIsLoading(type)) {
        return null;
    }

    return <div className="progress">Upload progress: {bulkUploadStore.getProgress(type)}%</div>;
});

export default BulkUploadProgress;
