import React from "react";

import { observer } from "mobx-react";
import state from "@app/state/store/report/import-search";
import UploadButton from "@app/components/form/upload/button";

import "./style/modal.scoped.scss";
import { ErrorCard } from "./error";

export const FileDetails = observer(() => {
    return (
        <div className="file">
            <div>Uploaded file </div>
            <div className="file-name">{state.file.name}</div>
            {state.searches.length > 0 && state.currentStep > 0 && (
                <div>We detected {state.searches.length} searches with the following details:</div>
            )}
        </div>
    );
});

export const UploadStep = observer(() => {
    return (
        <div className="upload-step">
            {!state.file && <p className="label">Upload search results from external file</p>}
            {state.file && <FileDetails />}
            {state.errors.file && <ErrorCard error={state.errors.file} />}
            <UploadButton onChange={(data) => state.setFile(data)} accept=".csv,.xls,.xlsx" />
        </div>
    );
});
