import React, { useState, useRef } from "react";
import { observer } from "mobx-react";
import { Checkbox, Tooltip } from "antd";
import classes from "classnames";
import GroupContext from "../context";
import "./style/option.scoped.scss";

const Option = observer(({ label, count, value, children }) => {
    const refLabel = useRef(null);

    const group = React.useContext(GroupContext);
    const selected = group.isSelected(value);

    const [hasTooltip, setHasTooltip] = useState(null);

    React.useEffect(() => {
        if (hasTooltip === null && refLabel?.current) {
            let overflow = refLabel.current.offsetWidth < refLabel.current.scrollWidth;
            setHasTooltip(overflow);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [label]);

    /**
     * Handle option click event
     */
    const onClick = () => {
        group.select({
            label,
            value,
            selected: !selected,
        });
    };

    const Count = observer(() => {
        if (count !== undefined) {
            return <div className="count">({count})</div>;
        }

        return null;
    });

    const Wrapper = observer(({ children }) => {
        if (hasTooltip) {
            return (
                <Tooltip placement="left" title={label}>
                    {children}
                </Tooltip>
            );
        }
        return <>{children}</>;
    });

    const Content = () => {
        if (children) {
            return children;
        }

        return label || null;
    };

    return (
        <>
            <Wrapper>
                <div
                    className={classes("option", {
                        selected: selected,
                    })}
                    onClick={onClick}
                >
                    <Checkbox className="checkbox" checked={selected} />
                    <div className="label" ref={refLabel}>
                        <Content />
                    </div>
                    <Count />
                </div>
            </Wrapper>
        </>
    );
});

export default Option;
