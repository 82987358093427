import React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import client from "@app/state/store/clients/details";

import Page from "@app/components/page/page";
import Header from "@app/components/setup/header";
import Reasons from "@app/components/setup/reason/group";
import ReviewToggles from "@app/components/setup/score/toggles";
import ScoreBands from "@app/components/setup/score/bands";
import AdditionalDataGroup from "@app/components/setup/additionalData/group";
import Questions from "@app/components/setup/question/group";

const SLR = observer(() => {
    const history = useHistory();
    const config = client.config?.slr;
    const l1 = config?.l1;
    const l2 = config?.l2;

    // validate the config is loaded
    if (!config) {
        return;
    }

    /**
     * Close the page
     */
    const close = async () => {
        history.push("../setup");
    };

    /**
     * Update the configuration values
     */
    const update = async (data, level) => {
        let result = {};

        if (level) {
            result[level] = {
                ...config[level],
                ...data,
            };
        } else {
            result = {
                ...config,
                data,
            };
        }
        await client.updateConfig({ slr: result });
    };

    /**
     * Render the page
     */
    return (
        <Page>
            <Page.Header title="DUE" closable onClose={close} />
            <Page.Body maxWidth={1000}>
                <Header title="L1: Screening" />
                <Reasons
                    title="Exclusion Reasons (L1)"
                    list={l1.excludeReasons}
                    onChange={(reasons) => {
                        update({ excludeReasons: reasons }, "l1");
                    }}
                />

                <Header title="L2: Article Review" className="mt-25" />

                <ReviewToggles
                    config={l2}
                    warn={false}
                    onChange={(toggles) => {
                        update(toggles, "l2");
                    }}
                />

                <Reasons
                    title="Exclusion Reasons (L2)"
                    list={l2.excludeReasons}
                    onChange={(reasons) => {
                        update({ excludeReasons: reasons }, "l2");
                    }}
                />
                <AdditionalDataGroup
                    list={config.additionalData}
                    onChange={(additionalData) => {
                        update({ additionalData });
                    }}
                />

                <Questions
                    list={l2.questions}
                    warn={false}
                    useScore={l2.enableScore}
                    onChange={(questions) => {
                        update({ questions }, "l2");
                    }}
                />

                {l2.enableScore && (
                    <ScoreBands
                        criteria={l2.criteria}
                        onChange={(criteria) => {
                            update({ criteria }, "l2");
                        }}
                    />
                )}
            </Page.Body>
        </Page>
    );
});

export default SLR;
