import React from "react";
import { Select, Button, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { observer, useLocalStore } from "mobx-react";
import confirm from "@app/components/confirm";

import AddVariantModal from "./add-variant-modal";
import "./style/variantMenu.scoped.scss";

const VariantMenu = observer(
    ({
        onSelectVariant,
        selectedVariant,
        variants,
        onAddVariant,
        onDeleteVariant,
        onRenameVariant,
    }) => {
        const modalState = useLocalStore(() => ({
            isEditing: false,
            isVisible: false,
            variantId: null,
        }));
        const variantOptions = variants.map((variant) => ({
            label: variant.title,
            value: variant._id,
        }));

        const handleSelectChange = (value) => {
            if (onSelectVariant) {
                onSelectVariant(value, "variant");
            }
        };

        const showModal = () => {
            modalState.isEditing = false;
            modalState.variantId = null;
            modalState.isVisible = true;
        };

        const handleCancel = () => {
            modalState.isVisible = false;
            modalState.isEditing = false;
            modalState.variantId = null;
        };

        const handleSubmit = async (values) => {
            if (!modalState.isEditing) {
                await onAddVariant({ title: values.title });
            } else {
                await onRenameVariant({ variantId: modalState.variantId, title: values.title });
            }

            modalState.isVisible = false;
            modalState.isEditing = false;
            modalState.variantId = null;
        };

        const handleDeleteVariant = async (e, value) => {
            e.stopPropagation();

            let proceed = await confirm("Are you sure you want to delete this variant?");

            if (proceed && onDeleteVariant) {
                onDeleteVariant({ variantId: value });
            }
        };

        const handleRenameVariant = (e, value) => {
            e.stopPropagation();

            modalState.variantId = value;
            modalState.isEditing = true;
            modalState.isVisible = true;
        };

        return (
            <>
                <div className="variantRow">
                    <Select
                        allowClear
                        autoClearSearchValue={false}
                        showArrow={true}
                        className="select"
                        placeholder="Select Variant"
                        onChange={handleSelectChange}
                        value={selectedVariant}
                    >
                        {variantOptions.map((option) => (
                            <Select.Option
                                key={option.value}
                                value={option.value}
                                label={option.label}
                            >
                                <div className="variant-option-container">
                                    <div className="variant-option-label">{option.label}</div>
                                    <div className="variant-option-actions">
                                        <Tooltip title="Rename Variant">
                                            <Button
                                                type="text"
                                                icon={<EditOutlined />}
                                                onClick={(event) =>
                                                    handleRenameVariant(event, option.value)
                                                }
                                            />
                                        </Tooltip>
                                        {variants.length > 1 && (
                                            <Tooltip title="Delete Variant">
                                                <Button
                                                    type="text"
                                                    icon={<DeleteOutlined />}
                                                    onClick={(event) =>
                                                        handleDeleteVariant(event, option.value)
                                                    }
                                                />
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>
                            </Select.Option>
                        ))}
                    </Select>
                    <Button type="primary" onClick={showModal}>
                        <span>Add Variant</span>
                    </Button>
                </div>
                <AddVariantModal
                    visible={modalState.isVisible}
                    isEditing={modalState.isEditing}
                    onCancel={handleCancel}
                    onSubmit={handleSubmit}
                    variants={variants}
                    variantId={modalState.variantId}
                />
            </>
        );
    },
);

export default VariantMenu;
