import React, { useEffect } from "react";

import { Divider, Select, List } from "antd";
import { observer } from "mobx-react";
import state from "@app/state/store/report/import-search";
import reportStore from "@app/state/store/report";

import { SearchType } from "@app/constants";

import "./style/modal.scoped.scss";

const ReasonDivider = () => {
    return (
        <div
            style={{
                cursor: "auto",
            }}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <Divider
                style={{
                    margin: "0",
                    fontSize: "12px",
                    fontWeight: "bolder",
                }}
            >
                OR
            </Divider>
            <div
                style={{
                    color: "#7284bd",
                    fontWeight: "bolder",
                }}
            >
                Map to an existing reason:
            </div>
        </div>
    );
};

const ReasonsList = observer(({ reasons, type, level }) => {
    const isL1 = level === "L1";
    const included = isL1 ? reasons.l1Included : reasons.l2Included;
    const excluded = isL1 ? reasons.l1Excluded : reasons.l2Excluded;
    const count = reasons.count;
    const excludeReasons = isL1
        ? reportStore.config[type].l1.excludeReasons
        : reportStore.config[type].l2.excludeReasons;

    useEffect(() => {
        reasons.reasons.forEach((reason) => {
            const exists = excludeReasons.find((r) => r.reason === reason.name);
            if (!exists) {
                if (isL1) {
                    state.setL1ReasonAction(type, reason.name, "new");
                } else {
                    state.setL2ReasonAction(type, reason.name, "new");
                }
            }
        });
    }, []);

    return (
        <div className="reasons" key={type}>
            <div className="reasons-header">
                {level} ({type === SearchType.SOTA ? "SoTA" : "DUE"}) : Screening{" "}
                <span className="values">
                    ({count} articles, {included} included, {excluded} excluded)
                </span>
            </div>
            <List
                itemLayout="horizontal"
                dataSource={reasons.reasons}
                renderItem={(item, index) => (
                    <List.Item
                        actions={[
                            <>
                                {excludeReasons.some((reason) => reason.reason === item.name) ? (
                                    <div className="reason-exists">Already exists</div>
                                ) : (
                                    <Select
                                        style={{ width: 200, textAlign: "left" }}
                                        value={item.action}
                                        defaultValue="new"
                                        placeholder="Select reason"
                                        onChange={(value) => {
                                            if (isL1) {
                                                state.setL1ReasonAction(type, item.name, value);
                                            } else {
                                                state.setL2ReasonAction(type, item.name, value);
                                            }
                                        }}
                                        options={[
                                            {
                                                label: "Add as a new reason",
                                                value: "new",
                                                key: "new",
                                            },
                                            {
                                                label: <ReasonDivider />,
                                                value: "map",
                                            },
                                            ...excludeReasons.map((reason) => ({
                                                label: reason.reason,
                                                value: reason._id,
                                                key: reason._id,
                                            })),
                                        ]}
                                    />
                                )}
                            </>,
                        ]}
                    >
                        <List.Item.Meta
                            title={
                                <div>
                                    {item.name}{" "}
                                    <span className="count">({item.count} articles)</span>
                                </div>
                            }
                        />
                    </List.Item>
                )}
            />
        </div>
    );
});

export const L1Reasons = observer(() => {
    return state.l1Reasons.map((reasons) => {
        return <ReasonsList key={reasons.type} reasons={reasons} type={reasons.type} level="L1" />;
    });
});

export const L2Reasons = observer(() => {
    return state.l2Reasons.map((reasons) => (
        <ReasonsList key={reasons.type} reasons={reasons} type={reasons.type} level="L2" />
    ));
});
