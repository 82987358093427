import React from "react";
import Group from "../group";
import { Select } from "antd";
import { ReferenceStyle } from "@app/constants";

// import "./style/databases.scoped.scss";

const Style = ({ config, onChange, readonly = false }) => {
    const update = async (args) => {
        const result = {
            ...config,
            ...args,
        };

        onChange && onChange(result);
    };

    return (
        <Group title="Reference Style">
            <div className="citation">
                <div className="row">
                    <Select
                        value={config?.style}
                        onChange={(style) => update({ style })}
                        options={[
                            {
                                value: ReferenceStyle.Vancouver,
                                label: "Vancouver",
                            },
                            {
                                value: ReferenceStyle.AMA,
                                label: "AMA",
                            },
                            {
                                value: ReferenceStyle.Chicago,
                                label: "Chicago (18th Edition, Author-Date)",
                            },
                        ]}
                        disabled={readonly}
                    />
                </div>
            </div>
        </Group>
    );
};

export default Style;
