import React from "react";
import { observer } from "mobx-react";

import AdditionalDataL2 from "./additionalDataL2";

const AdditionalData = observer(({ stage }) => {
    return <AdditionalDataL2 stage={stage} />;
});

export default AdditionalData;
