import React, { useState } from "react";
import { Modal, Upload, message, Button } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import bulkUploadStore from "@app/state/store/report/bulk-upload";

const { Dragger } = Upload;

const BulkUploadModal = observer(() => {
    const [fileList, setFileList] = useState([]);

    const { modalState } = bulkUploadStore;

    const handleOk = async () => {
        if (fileList.length === 0) {
            message.error("Please select files to upload");
            return;
        }

        try {
            bulkUploadStore.toggleModal(false);
            bulkUploadStore.upload(fileList);

            setFileList([]);
        } catch (error) {
            console.log(error);
            message.error("Upload failed");
        }
    };

    const handleCancel = () => {
        bulkUploadStore.toggleModal(false);
        setFileList([]);
    };

    const onFileChange = ({ file, fileList }) => {
        // Filter out duplicate files based on file name and size
        const uniqueFiles = fileList.reduce((acc, current) => {
            const isDuplicate = acc.find(
                (item) => item.name === current.name && item.size === current.size,
            );
            if (!isDuplicate) {
                acc.push(current);
            }
            return acc;
        }, []);

        setFileList(uniqueFiles);
    };

    const props = {
        accept: "application/pdf",
        multiple: true,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
        onChange: onFileChange,
    };

    return (
        <Modal
            title="Bulk Upload"
            visible={modalState.open}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose={false}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    Upload
                </Button>,
            ]}
        >
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag files to this area to upload</p>
                <p className="ant-upload-hint">Support for multiple file uploads.</p>
            </Dragger>
        </Modal>
    );
});

export default BulkUploadModal;
