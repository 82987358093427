import React from "react";

import { Divider } from "antd";
import { observer } from "mobx-react";
import state from "@app/state/store/report/import-search";

import "./style/modal.scoped.scss";
import { ErrorCard } from "./error";

const ReviewCard = observer(({ search }) => {
    return (
        <div className="search-summary-card">
            <div className="title">
                <div className="name">{search.name}</div>
                <div className="found">({search.articlesFound} articles found)</div>
                <div className="database">{search.database}</div>
            </div>
            <div className="details">
                <div className="levels">
                    <div className="label">
                        L1 Included/Excluded:{" "}
                        <span className="value">
                            {search.l1Included}/{search.l1Excluded}
                        </span>
                    </div>
                    <Divider type="vertical" orientation="center" />
                    <div className="label">
                        L2 Included/Excluded:{" "}
                        <span className="value">
                            {search.l2Included}/{search.l2Excluded}
                        </span>
                    </div>
                    <Divider type="vertical" orientation="center" />
                    <div className="label">
                        Type: <span className="value">{search.type}</span>
                    </div>
                    <Divider type="vertical" orientation="center" />
                    <div className="label">
                        Category: <span className="value">{search.category}</span>
                    </div>
                </div>
                <div className="levels">
                    <div className="label">
                        Search Query: <span className="value query">{search.searchQuery}</span>
                    </div>
                </div>
            </div>
        </div>
    );
});

export const Review = observer(() => {
    const { l1Included, l1Excluded, l2Included, l2Excluded } = state.searches.reduce(
        (acc, search) => {
            acc.l1Included += search.l1Included;
            acc.l1Excluded += search.l1Excluded;
            acc.l2Included += search.l2Included;
            acc.l2Excluded += search.l2Excluded;
            return acc;
        },
        {
            l1Included: 0,
            l1Excluded: 0,
            l2Included: 0,
            l2Excluded: 0,
        },
    );

    return (
        <div className="review-step">
            <div className="header">
                <div className="value mr">Total for the uploaded document: </div>
                <div className="label">
                    L1 Included/Excluded:{" "}
                    <span className="value">
                        {l1Included}/{l1Excluded}
                    </span>
                </div>
                <Divider type="vertical" orientation="center" />
                <div className="label">
                    L2 Included/Excluded:{" "}
                    <span className="value">
                        {l2Included}/{l2Excluded}
                    </span>
                </div>
            </div>
            {state.errors.review && <ErrorCard error={state.errors.review} />}

            <div className="search-summary">
                {state.searches.map((search) => (
                    <ReviewCard search={search} key={search.name} />
                ))}
            </div>
        </div>
    );
});
