import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import qs from "qs";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Page } from "@app/components/page";
import Filter from "@app/components/filter";
import EmptyList from "@app/components/list/empty";
import Card from "@app/components/article/cards/l1";
import ArticleFilter from "@app/components/article/filter";
import MultiActionModal from "@app/components/article/multi-action-modal";
import { useContext as useMultiSelectContext } from "@app/components/multi-select/context";
import { MultiSelect, MultiAction } from "@app/components/multi-select";
import useHistoryFilters from "@app/components/hooks/useHistoryFilters";
import { Button, Menu, Dropdown } from "antd";
import { FileExcelOutlined, FileSyncOutlined, DownOutlined } from "@ant-design/icons";
import { SearchType, Review, ReasonType } from "@app/constants";
import { useSplitPage } from "@app/components/page/split/context";
import { useHistory } from "react-router-dom";
import { events } from "@app/lib/store";
import OriginalArticleModal from "@app/components/article/original-article-modal";

import reportStore from "@app/state/store/report";
import articleStore from "@app/state/store/report/article";
import session from "@app/state/store/session";
import l1 from "@app/state/store/report/l1";
import articleActions from "@app/state/store/action/article";
import { BulkUploadMenuItem, BulkUploadProgress } from "@app/components/bulk-upload";

const List = observer(({ className, ...rest }) => {
    const history = useHistory();
    const split = useSplitPage();
    const { article, type } = useParams();
    const config = reportStore.config;
    const project = reportStore.project;
    const filter = l1.filter[type];
    const readonly = !session.can("article.update") || reportStore.readOnly;

    useHistoryFilters(filter);

    const [multiActionModalOpen, setMultiActionModalOpen] = useState(false);
    const [originalArticleOpen, setOriginalArticleOpen] = useState(false);

    events.removeAllListeners(["article.updateMany"]);
    events.on("article.updateMany", () => {
        // refresh list
        l1.load(type);
    });

    // initialize the state when component mounts.
    useEffect(() => {
        l1.load(type);
        articleActions.setContext({ stage: Review.L1, type });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [l1.project, type]);

    useEffect(() => {
        l1.article = article;
    }, [article]);

    /**
     * Focus an article
     */
    const focus = (id) => {
        l1.article = id;
    };

    // calculate the base url; Should end in /l1 when SLR ro SoTA is enabled,
    // and /review when type = sota and sota is not enabled.
    const baseUrl =
        type === SearchType.SLR || l1.sotaWorkflowEnabled
            ? `/r/${l1.project}/${type}/l1`
            : `/r/${l1.project}/${type}/noreview`;
    const showFilter = !split.isSplit;

    // Calculate Export URL
    let exportUrl = `/api/project/${l1.project}/articles/l1/export?`;

    const params = filter.value();
    params.type = type;

    const queryString = qs.stringify(params);
    exportUrl += queryString;

    /**
     * Prepare the export menu
     */
    const exportMenu = (
        <Menu className="menu">
            <Menu.Item key="bulk-upload">
                <BulkUploadMenuItem stage={Review.L1} type={type} />
            </Menu.Item>
            <Menu.Divider key="divider" className={"divider"} />
            <Menu.Item key="excel" icon={<FileExcelOutlined />}>
                <a href={exportUrl + "&format=excel"} target="_blank" rel="noopener noreferrer">
                    Export Excel
                </a>
            </Menu.Item>
            <Menu.Item key="fail" icon={<FileSyncOutlined />}>
                <a href={exportUrl + "&format=zotero"} target="_blank" rel="noopener noreferrer">
                    Export RIS
                </a>
            </Menu.Item>
        </Menu>
    );

    const MultiSelectWrapper = observer(() => {
        const { context } = useMultiSelectContext();
        const { selected, reset } = context;

        // disable the multi action dropdown
        if (readonly) {
            return <Filter.Header filter={filter} toggle={showFilter} sorting={showFilter} />;
        }
        const checkedArticlesIds = selected
            ?.filter((item) => item.checked)
            ?.map((item) => item._id);

        return (
            <>
                <Filter.Header
                    filter={filter}
                    toggle={showFilter}
                    sorting={showFilter}
                    showMultiAction={true}
                    multiAction={
                        !readonly && (
                            <MultiAction handleUpdate={() => setMultiActionModalOpen(true)} />
                        )
                    }
                    afterSearch={() => {
                        reset();
                    }}
                />
                <MultiActionModal
                    modalOpen={multiActionModalOpen}
                    setModalOpen={setMultiActionModalOpen}
                    articleIds={checkedArticlesIds}
                    afterSubmit={(props) => {
                        if (props?.showOriginalArticleModal) {
                            setOriginalArticleOpen(true);
                        } else {
                            reset();
                        }
                    }}
                    values={{ type }}
                    enableAction
                />
                {originalArticleOpen && (
                    <OriginalArticleModal
                        type={type}
                        modalOpen={originalArticleOpen}
                        setModalOpen={setOriginalArticleOpen}
                        articleIds={checkedArticlesIds}
                        afterSelect={async () => {
                            const duplicateReason = config?.[type]?.l1?.excludeReasons.find(
                                (r) => r.reason === ReasonType.DUPLICATE,
                            );
                            const action = {
                                type: Review.FAIL,
                                reason: duplicateReason,
                            };
                            articleStore.updateMany(checkedArticlesIds, type, { action });
                        }}
                    />
                )}
            </>
        );
    });

    const sanitizeLocationSearch = (search) => {
        const { snippet, area, ...rest } = qs.parse(search, { ignoreQueryPrefix: true });
        return qs.stringify(rest);
    };

    return (
        <Page className={classNames("l1-list", className)} {...rest}>
            <Page.Header>
                <Page.Header.Left shrink>
                    <Page.Title>L1 Review</Page.Title>
                </Page.Header.Left>
                <Page.Header.Right>
                    <BulkUploadProgress type={type} />

                    <Dropdown overlay={exportMenu} trigger="click" placement="bottomRight">
                        <Button>
                            Actions <DownOutlined />
                        </Button>
                    </Dropdown>
                </Page.Header.Right>
            </Page.Header>

            <MultiSelect totalList={l1.totalList}>
                <MultiSelectWrapper />
                <Page.Layout>
                    <Page.Body filter={filter}>
                        <EmptyList
                            title="No Articles Found"
                            show={l1.list.length === 0 && !l1.loading}
                        ></EmptyList>

                        {l1.list.map((article) => {
                            return (
                                <Link
                                    to={{
                                        pathname: `${baseUrl}/${article._id}`,
                                        search: sanitizeLocationSearch(history.location.search),
                                    }}
                                    onClick={() => focus(article._id)}
                                    key={article._id}
                                    style={{ width: "100%" }}
                                >
                                    <Card
                                        article={article}
                                        type={type}
                                        refStyle={config.reference?.style}
                                        keywords={config[type]?.keywords}
                                        focus={l1.article === article._id}
                                        readonly={readonly}
                                        checkedBox={!readonly}
                                    />
                                </Link>
                            );
                        })}
                    </Page.Body>

                    {showFilter && (
                        <ArticleFilter
                            filter={filter}
                            type={type}
                            project={project}
                            config={config}
                            searches={l1.searches}
                        />
                    )}
                </Page.Layout>

                <Page.Footer>
                    <Filter.Pager filter={filter} />
                </Page.Footer>
            </MultiSelect>
        </Page>
    );
});

export default List;
