import { action, computed } from "mobx";
import { v4 as uuid } from "uuid";

import BaseStore from "../base";

import { UploadService } from "@app/components/form/upload/service";

// report wrapper state
import report from "../report";
import { events } from "@app/lib/store";

class FileState {
    constructor(name, type) {
        this.name = name;
        this.type = type;
        this.status = "pending";
        this.resultCode = null;
    }
}

/**
 * State management controlling the bulk upload process of articles for due/sota l1 and l2
 */
export class BulkUploadStore extends BaseStore {
    uploads = [];

    /**
     * Observable store data
     */
    observable() {
        return {
            uploads: [],
            modalState: {
                open: false,
                type: null,
            },
            resultModalOpen: false,
        };
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    @computed get client() {
        return report.project?.client?._id;
    }

    getProgress(type) {
        const uploads = this.uploads.filter((upload) => upload.type === type);

        if (uploads.length === 0) {
            return 0;
        }

        const total = uploads.length;
        const success = uploads.filter((upload) => upload.status === "success").length;

        return Math.round((success / total) * 100);
    }

    getIsLoading(type) {
        const uploads = this.uploads.filter((upload) => upload.type === type);

        return uploads.find((upload) => upload.status === "loading");
    }

    constructor() {
        super();

        events.on("project.unload", () => {
            this.reset();
        });
    }

    @action
    async upload(filesList) {
        this.uploads = [];
        const uploadId = uuid();

        const { type } = this.modalState;

        // Initialize uploads array with initial states
        this.uploads = filesList.map((fileWrapper) => new FileState(fileWrapper.name, type));

        for (const fileWrapper of filesList) {
            const file = fileWrapper.originFileObj || fileWrapper;

            // Create new array with updated loading status
            this.uploads = this.uploads.map((upload) =>
                upload.name === file.name ? { ...upload, status: "loading" } : upload,
            );

            try {
                const { data } = await UploadService.upload(
                    file,
                    {
                        uploadId,
                        type,
                        clientId: this.client,
                    },
                    `/project/${this.project}/bulk-upload`,
                );

                // Create new array with success status
                this.uploads = this.uploads.map((upload) =>
                    upload.name === file.name
                        ? { ...upload, status: "success", resultCode: data.code }
                        : upload,
                );
            } catch (error) {
                // Create new array with error status
                this.uploads = this.uploads.map((upload) =>
                    upload.name === file.name
                        ? { ...upload, status: "error", resultCode: error.code }
                        : upload,
                );

                console.error(error);
            }
        }
    }

    @action.bound
    toggleModal(open, type) {
        if (type) {
            this.modalState.type = type;
        }

        if (typeof open === "boolean") {
            this.modalState.open = open;
        } else {
            this.modalState.open = !this.modalState.open;
        }
    }

    @action.bound
    toggleResultsModal(open) {
        if (typeof open === "boolean") {
            this.resultModalOpen = open;
        } else {
            this.resultModalOpen = !this.resultModalOpen;
        }
    }
}

export default new BulkUploadStore();
