import React from "react";
import { Button } from "antd";
import Modal from "@app/components/modal";
import applicationStore from "@app/state/store/application";
import session from "@app/state/store/session";

const SessionExpiredModal = ({ visible }) => {
    const [, setLastReloginTime] = React.useState(null);

    const refreshPage = () => {
        window.location.reload(false);
    };

    const isSSOEnabled = applicationStore.settings?.sso?.enabled;

    const handleOktaLogin = () => {
        setLastReloginTime(new Date());
        session.setReloginLoading(true);
        session.loginOkta({ relogin: true });
    };

    return (
        <Modal
            visible={visible}
            footer={null}
            closable={false}
            centered
            style={{ textAlign: "center" }}
        >
            <div style={{ marginBottom: "1rem" }}>
                Your session has expired.
                <br />
                Click here to sign in
            </div>
            {isSSOEnabled ? (
                <Button
                    type="primary"
                    size="large"
                    onClick={handleOktaLogin}
                    style={{ width: "100%" }}
                    disabled={session.reloginLoading}
                >
                    Sign In using Okta
                </Button>
            ) : (
                <Button onClick={refreshPage} type="primary">
                    Sign In
                </Button>
            )}
        </Modal>
    );
};

export default SessionExpiredModal;
