import React from "react";
import { SearchType } from "@app/constants";
import { ReadOutlined, DeploymentUnitOutlined } from "@ant-design/icons";

import "./style/type.scoped.scss";

const options = {
    [SearchType.SLR]: { label: "DUE", icon: <ReadOutlined /> },
    [SearchType.SOTA]: { label: "SoTA", icon: <DeploymentUnitOutlined /> },
};

const Type = ({ value = SearchType.SLR, iconOnly = false, ...rest }) => {
    const option = options[value];

    return (
        <div className={`search-type ${value} ${rest.disabled ? "disabled" : ""}`} {...rest}>
            <i>{option.icon}</i>
            {!iconOnly && <em>{option.label}</em>}
        </div>
    );
};

export default Type;
