import React from "react";
import { Modal, Button, message } from "antd";
import { observer } from "mobx-react";
import classNames from "classnames";
import bulkUploadStore from "@app/state/store/report/bulk-upload";

import "./style/results-modal.scoped.scss";

const BulkUploadResultsModal = observer(() => {
    const { resultModalOpen, uploads } = bulkUploadStore;

    const results = uploads
        .map((upload) => {
            let description = "";
            let status = "";

            switch (upload.resultCode) {
                case "NO_DOI_FOUND":
                    description = "No DOI found in document";
                    status = "error";
                    break;
                case "ARTICLE_NOT_FOUND":
                    description = "Article with matching DOI/Title not found";
                    status = "error";
                    break;
                case "ARTICLE_ALREADY_HAS_A_FILE":
                    description = "Article already has a PDF attached";
                    status = "warning";
                    break;
                case "FILE_SAVED":
                    description = "File saved";
                    status = "success";
                    break;
                default:
                    description = "Upload failed";
                    status = "error";
                    break;
            }

            return {
                name: upload.name,
                status,
                description,
            };
        })
        .sort((a, b) => {
            const statusOrder = { error: 0, warning: 1, success: 2 };
            return statusOrder[a.status] - statusOrder[b.status];
        });

    const handleOk = async () => {
        const csvHeader = "File Name,Status,Description\n";
        const csvContent = results
            .map((result) => `"${result.name}","${result.status}","${result.description}"`)
            .join("\n");
        const csvString = csvHeader + csvContent;

        try {
            await navigator.clipboard.writeText(csvString);
            message.success("Results copied to clipboard");
        } catch (err) {
            message.error("Failed to copy results");
            console.error("Copy failed", err);
        }
    };

    const handleCancel = () => {
        bulkUploadStore.toggleResultsModal(false);
    };

    return (
        <Modal
            title="Bulk Upload Result"
            visible={resultModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose={false}
            width="80%" // Set modal width to 80% of viewport
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Close
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    Copy to clipboard
                </Button>,
            ]}
        >
            <table style={{ width: "100%" }}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>File Name</th>
                        <th>Status</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map((result, index) => (
                        <tr
                            key={result.name}
                            className={classNames({
                                warning: result.status === "warning",
                                success: result.status === "success",
                                error: result.status === "error",
                            })}
                        >
                            <td>{index + 1}</td>
                            <td>{result.name}</td>
                            <td>{result.status}</td>
                            <td>{result.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Modal>
    );
});

export default BulkUploadResultsModal;
