import React, { useEffect } from "react";

import { Divider, Form, Input, Select } from "antd";
import { FileSearchOutlined, PlusSquareOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import state from "@app/state/store/report/import-search";
import reportStore from "@app/state/store/report";

import SearchSource from "@app/components/search/source";
import { SearchCategory, SearchType, Search } from "@app/constants";
import Type from "../type";
import { FileDetails } from "./upload";

import "./style/modal.scoped.scss";

const SearchSummaryCard = observer(({ search, index }) => {
    const [form] = Form.useForm();

    const sotaEnabled = reportStore.config?.sota?.enabled;
    const searchConfig = reportStore.config?.search || {};

    const customDatabases = searchConfig.customDatabases?.map((db) => db.name) || [];

    const enabledSearchOption = Object.values(Search)
        .filter((search) => searchConfig[search])
        .concat(customDatabases);

    const validateSearch = (index, isValid) => {
        state.searches[index].isValid = isValid;
    };

    const validateFields = async () => {
        try {
            await form.validateFields();
            validateSearch(index, true);
        } catch (e) {
            if (e.errorFields.length === 0) {
                validateSearch(index, true);
            } else {
                validateSearch(index, false);
            }
        }
    };

    useEffect(() => {
        state.searches[index].isValid = false;
        state.searches[index].validateFields = validateFields;
    }, []);

    const updateSearch = (values) => {
        delete values.name;
        state.searches[index] = {
            ...state.searches[index],
            ...values,
        };
    };

    return (
        <div className="search-summary-card">
            <div className="header">
                <div className="title">
                    <div className="found">{search.articlesFound} articles found</div>
                </div>
                <div className="details">
                    <div></div>
                    <div className="levels">
                        <div className="label">
                            L1 Included/Excluded:{" "}
                            <span className="value">
                                {search.l1Included}/{search.l1Excluded}
                            </span>
                        </div>
                        <Divider type="vertical" orientation="center" />
                        <div className="label">
                            L2 Included/Excluded:{" "}
                            <span className="value">
                                {search.l2Included}/{search.l2Excluded}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="search-details">
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={{
                        searchQuery: search.searchQuery,
                        name: search.name,
                        type: search.type,
                        category: search.category,
                        database: enabledSearchOption.includes(search.database)
                            ? search.database
                            : undefined,
                    }}
                    onValuesChange={updateSearch}
                >
                    <Form.Item
                        style={{ marginBottom: "4px" }}
                        label="Search name"
                        name="name"
                        onBlur={() => {
                            state.searches[index].name = form.getFieldValue("name");
                        }}
                    >
                        <Input placeholder="Leave blank for system generated name" />
                    </Form.Item>

                    <Form.Item
                        style={{ marginBottom: "4px", width: "100%" }}
                        label="Search Query"
                        name="searchQuery"
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <div className="form-row">
                        <Form.Item
                            style={{ marginBottom: "4px", width: "33%" }}
                            label="Database"
                            name="database"
                            rules={[{ required: true, message: "Please select a database" }]}
                        >
                            <Select placeholder="Database">
                                {enabledSearchOption.map((search) => (
                                    <Select.Option key={search} value={search}>
                                        <SearchSource name={search} />
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            style={{ marginBottom: "4px", width: "33%" }}
                            label="Type"
                            name="type"
                            rules={[{ required: true, message: "Please select a type" }]}
                        >
                            <Select placeholder="Type" disabled={!sotaEnabled}>
                                <Select.Option value={SearchType.SLR}>
                                    <Type value={SearchType.SLR} disabled={!sotaEnabled} />
                                </Select.Option>
                                <Select.Option value={SearchType.SOTA}>
                                    <Type value={SearchType.SOTA} disabled={!sotaEnabled} />
                                </Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            style={{ marginBottom: "4px", width: "33%" }}
                            label="Category"
                            name="category"
                            rules={[{ required: true, message: "Please select a category" }]}
                        >
                            <Select placeholder="Category">
                                <Select.Option value={SearchCategory.SYSTEMATIC_SEARCH}>
                                    <FileSearchOutlined /> {SearchCategory.SYSTEMATIC_SEARCH}
                                </Select.Option>
                                <Select.Option value={SearchCategory.GREY_LITERATURE}>
                                    <PlusSquareOutlined /> {SearchCategory.GREY_LITERATURE}
                                </Select.Option>
                                <Select.Option value={SearchCategory.FOCUSED_SEARCH}>
                                    <FullscreenExitOutlined /> {SearchCategory.FOCUSED_SEARCH}
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </div>
    );
});

export const SearchSummary = observer(() => {
    return (
        <div className="search-summary">
            {state.file && <FileDetails />}
            {state.searches.map((search, index) => (
                <SearchSummaryCard search={search} key={search.name} index={index} />
            ))}
        </div>
    );
});
