import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Button, Select } from "antd";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import Wand from "@app/assets/icons/wand";
import AdditionalDataField from "./additionalDataField";
import { events } from "@app/lib/store";

import articleStore from "@app/state/store/report/article";
import reportStore from "@app/state/store/report";
import session from "@app/state/store/session";
import applicationStore from "@app/state/store/application";

import VariantMenu from "./variantMenu";

import "../style/icon.scoped.scss";
import "./style/additionalData.scoped.scss";

const AdditionalData = observer(() => {
    const { type } = useParams();

    const filterStoreName = `${type}_additionalData_filter`;

    const article = articleStore.article;
    const config = reportStore.config?.[type];
    const hasDefinedFields = config?.additionalData?.length > 0;
    const readonly = !session.can("article.update") || reportStore.readOnly;
    const processing = article.processing?.additionalData;

    const [additionalDataItems, setAdditionalDataItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedVariant, setSelectedVariant] = useState();
    const [variants, setVariants] = useState([]);
    const [showAI, setShowAI] = useState(false);

    const showAIValue = (additionalData) => {
        let result = false;
        if (applicationStore?.enableAI && article.file && !processing) {
            // if there is a field with question and no suggestion
            const needsSuggestionField = additionalData.find(
                (item) => item.ai?.question && !item.ai?.suggestion,
            );
            if (needsSuggestionField) {
                result = true;
            }
        }

        return result;
    };

    const getMergedAdditionalData = () => {
        const additionalDataMap = new Map();

        const getKey = (itemId, variantId) => {
            return `${itemId}.${variantId || "default"}`;
        };

        const getMergedItem = (item, variantId) => {
            const key = getKey(item._id, variantId);
            const dataMapItem = additionalDataMap.get(key) || { value: "" };

            return {
                ...item,
                ai: {
                    ...item?.ai,
                    ...dataMapItem?.ai,
                },
                value: dataMapItem.value,
                variantId,
            };
        };

        // if no additionalData, return empty array
        if (!config?.additionalData?.length > 0) {
            return [];
        }

        article.additionalData.forEach((item) => {
            additionalDataMap.set(getKey(item._id, item.variantId), item);
        });

        // if variants, return the merged additionalData for each variant
        return article.additionalDataVariants
            .map((variant) => {
                return config?.additionalData?.map((item) => {
                    return getMergedItem(item, variant._id);
                });
            })
            .flat();
    };
    const getFilteredFields = (items, fieldValues, variantId) => {
        return items.filter((item) => {
            if (!variantId) {
                return fieldValues?.length ? fieldValues.includes(item._id) : true;
            }

            const isDefaultVariant = variantId === "default";
            const matchesVariant = isDefaultVariant
                ? item.variantId === undefined
                : item.variantId === variantId;

            return matchesVariant && (!fieldValues?.length || fieldValues.includes(item._id));
        });
    };

    const setup = () => {
        const mergedItems = getMergedAdditionalData();
        setVariants(article.additionalDataVariants);
        setShowAI(showAIValue(mergedItems));

        // Tries to pre-populate the filter from the store
        if (reportStore.temp?.[filterStoreName]?.length > 0) {
            const { fieldValues, variantId } = reportStore.temp?.[filterStoreName];
            const retrievedSelectedItems = fieldValues.map((item) => item.id);
            setSelectedItems(retrievedSelectedItems);
            setSelectedVariant(variantId);

            const filteredItems = getFilteredFields(mergedItems, retrievedSelectedItems, variantId);

            setAdditionalDataItems(filteredItems);
        } else {
            setSelectedItems([]);
            setAdditionalDataItems(mergedItems);
        }
    };

    useEffect(() => {
        setup();
        events.on("article.update", setup);

        return () => {
            events.removeListener("article.update", setup);
        };
    }, []);

    const saveAdditionalData = async (additionalData) => {
        await articleStore.updateAdditionalData({
            additionalData,
            type,
        });
    };

    const generateSuggestions = async () => {
        if (processing) {
            return;
        }

        articleStore.generateSuggestionsForAdditionalData({ type });
        setShowAI(false);
    };

    const selectOptions =
        config?.additionalData?.map((item) => {
            return {
                _id: item._id,
                label: item.title,
                value: item._id,
            };
        }) || [];

    const handleSelectChange = (values, type) => {
        const mergedItems = getMergedAdditionalData();

        let fieldValues = selectedItems;
        let variantId = selectedVariant;

        if (type === "field") {
            setSelectedItems(values);
            fieldValues = values;
        } else {
            setSelectedVariant(values);
            variantId = values;
        }

        // populate the filter to the store
        reportStore.addToTemp(filterStoreName, {
            fieldValues: fieldValues.map((value) => ({
                id: value,
            })),
            variantId,
        });

        const filteredItems = getFilteredFields(mergedItems, fieldValues, variantId);
        setAdditionalDataItems(filteredItems);
    };

    const orderedAdditionalData = [];

    const onAddVariant = async ({ title }) => {
        await articleStore.addVariant({ title });
    };

    const onRenameVariant = async ({ variantId, title }) => {
        await articleStore.renameVariant({ variantId, title });
    };

    const onDeleteVariant = async ({ variantId }) => {
        await articleStore.deleteVariant({ variantId });
    };

    if (config.additionalData) {
        config.additionalData.forEach((item) => {
            const fieldVariants = additionalDataItems.filter(({ _id }) => _id === item._id) || [];
            orderedAdditionalData.push(...fieldVariants);
        });
    } else {
        orderedAdditionalData.push(...additionalDataItems);
    }

    return (
        <div data-testid="article-sidebar-additionalData" className="container">
            {!config?.additionalData?.length ? (
                <div className="emptyIconContainer">
                    <div className="icon">
                        <InfoCircleOutlined />
                    </div>

                    <div className="text">
                        There are no data extraction fields for this article.
                        <br />
                        You can add data extraction fields at the project setup.
                    </div>
                </div>
            ) : (
                <>
                    <div className="actionMenu">
                        {processing ? (
                            <Button icon={<LoadingOutlined />} type="icon">
                                <span>Processing Data Fields...</span>
                            </Button>
                        ) : (
                            !readonly &&
                            showAI && (
                                <Button icon={<Wand />} type="icon" onClick={generateSuggestions}>
                                    <span>Generate Suggestions</span>
                                </Button>
                            )
                        )}
                    </div>

                    {hasDefinedFields && (
                        <div>
                            <VariantMenu
                                config={config}
                                selectedVariant={selectedVariant}
                                onSelectVariant={handleSelectChange}
                                variants={variants}
                                onAddVariant={onAddVariant}
                                onRenameVariant={onRenameVariant}
                                onDeleteVariant={onDeleteVariant}
                            />
                            <Select
                                mode="multiple"
                                allowClear
                                autoClearSearchValue={false}
                                showArrow={true}
                                className="select"
                                placeholder="Filter data fields"
                                maxTagCount={0}
                                maxTagPlaceholder={() => (
                                    <span>
                                        {`Showing ${selectedItems.length} of ${selectOptions.length} field types`}
                                    </span>
                                )}
                                onChange={(value) => handleSelectChange(value, "field")}
                                value={selectedItems}
                                options={selectOptions}
                            />
                        </div>
                    )}

                    {orderedAdditionalData.map((item) => (
                        <AdditionalDataField
                            additionalData={item}
                            options={item.options}
                            readonly={readonly}
                            onChange={saveAdditionalData}
                            key={`${item._id}.${item.variantId}`}
                            variants={variants}
                        />
                    ))}
                </>
            )}
        </div>
    );
});

export default AdditionalData;
