import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { CopyOutlined, LinkOutlined } from "@ant-design/icons";
import Avatar from "@app/components/user/avatar";
import format from "@app/lib/format";
import logo from "@app/assets/logo-icon2.svg";

import "./style/ai-chat.scoped.scss";

const AIChatHistory = observer(({ history: messageHistory = [] }) => {
    const bottomRef = useRef(null);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (bottomRef && bottomRef.current) {
            bottomRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, [messageHistory.length]);

    /*
     * Open the file and highlight the source
     */
    function highlightSource(source) {
        const points = source.coordinates.points;
        const width = source.coordinates.layout_width;
        const height = source.coordinates.layout_height;

        const x1 = (points[0][0] / width) * 100; // x from top left corner
        const x2 = (points[2][0] / width) * 100; // x from bottom right corner
        const y1 = (points[0][1] / height) * 100; // y from top left corner
        const y2 = (points[1][1] / height) * 100; // y from bottom left corner

        let area = {
            pageIndex: source.page - 1,
            left: x1,
            top: y1,
            height: y2 - y1,
            width: x2 - x1,
        };

        let link = location.pathname + `?view-file=true&focus=true&area=${JSON.stringify(area)}`;
        history.push(link);
    }

    const ChatItem = ({ item, showCopy = false }) => {
        const defaultUser = {
            fullName: "CAPTIS Copilot",
        };
        const user = item.user || defaultUser;

        const copy = () => {
            const data = {};
            data["text/plain"] = new Blob([item.content], { type: "text/plain" });
            navigator.clipboard.write([new window.ClipboardItem(data)]);
        };

        return (
            <div className="chatItem">
                <div className="chatSignature">
                    <Tooltip title={user.fullName} placement="topLeft">
                        {item.user ? (
                            <Avatar className={`avatar`} user={user} size={40} />
                        ) : (
                            <img src={logo} className="logo" />
                        )}
                    </Tooltip>
                    <div>
                        <div>{user?.fullName}</div>
                        <div className="dateTime">{format.datetime(item.created)}</div>
                    </div>
                    {showCopy && (
                        <Button
                            type="icon"
                            icon={<CopyOutlined />}
                            className="copy"
                            onClick={copy}
                        />
                    )}
                </div>
                <div className="chatContent">{item.content}</div>
                {item.source?.page && (
                    <div className="link" onClick={() => highlightSource(item.source)}>
                        <LinkOutlined /> Source
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="historyContainer">
            {messageHistory.map((item) => {
                return (
                    <div key={item._id} className="historyGroup">
                        <ChatItem item={item} />
                        {item.answer && (
                            <div className="answer">
                                <ChatItem item={item.answer} showCopy={true} />
                            </div>
                        )}
                    </div>
                );
            })}
            <div ref={bottomRef}></div>
        </div>
    );
});

export default AIChatHistory;
