import React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import Page from "@app/components/page/page";
import Header from "@app/components/setup/header";
import Reasons from "@app/components/setup/reason/group";
import ReviewToggles from "@app/components/setup/score/toggles";
import ScoreBands from "@app/components/setup/score/bands";
import AdditionalDataGroup from "@app/components/setup/additionalData/group";
import Questions from "@app/components/setup/question/group";

import notify from "@app/components/notify";

import setup from "@app/state/store/projects/setup";
import Keywords from "@app/components/setup/keywords/group";
import report from "@app/state/store/report";

const SLR = observer(() => {
    const history = useHistory();
    const config = setup.config?.slr;
    const l1 = config?.l1;
    const l2 = config?.l2;
    const readonly = report.readOnly || setup.isComplete;

    // validate the config is loaded
    if (!config) {
        return;
    }

    /**
     * Close the page
     */
    const close = async () => {
        history.push("../setup");
    };

    /**
     * Update the configuration values
     */
    const update = async (data, level, args = {}) => {
        try {
            await setup.updateConfig({
                slr: {
                    [level]: {
                        ...config[level],
                        ...data,
                    },
                },
            });
        } catch (ex) {
            const message = args.error || "Error updating the configuration";
            notify.error(message);
        }
    };

    /**
     * Render the page
     */
    return (
        <Page>
            <Page.Header title="DUE" closable onClose={close} />
            <Page.Body maxWidth={1000}>
                <Header title="L1: Screening" />
                <Keywords
                    list={config.keywords}
                    onChange={async (keywords) => {
                        await setup.updateConfig({
                            slr: {
                                ...config,
                                keywords,
                            },
                        });
                    }}
                    readonly={readonly}
                />
                <Reasons
                    title="Exclusion Reasons (L1)"
                    list={l1.excludeReasons}
                    onChange={(reasons) => {
                        const error =
                            `Unable to remove the exclusion reason. ` +
                            `Please validate the change and make sure it is not used in the project before trying again.`;

                        update({ excludeReasons: reasons }, "l1", { error });
                    }}
                    readonly={readonly}
                />

                <Header title="L2: Article Review" className="mt-25" />

                <ReviewToggles
                    config={l2}
                    onChange={(toggles) => {
                        update(toggles, "l2");
                    }}
                    readonly={readonly}
                />

                <Reasons
                    title="Exclusion Reasons (L2)"
                    list={l2.excludeReasons}
                    onChange={(reasons) => {
                        const error =
                            `Unable to remove the exclusion reason. ` +
                            `Please validate the change make sure it is not used in the project before trying again.`;

                        update({ excludeReasons: reasons }, "l2", { error });
                    }}
                    readonly={readonly}
                />
                <AdditionalDataGroup
                    list={config.additionalData}
                    onChange={async (additionalData) => {
                        await setup.updateConfig({
                            slr: {
                                ...config,
                                additionalData,
                            },
                        });
                    }}
                    readonly={readonly}
                />

                <Questions
                    list={l2.questions}
                    useScore={l2.enableScore}
                    onChange={(questions) => {
                        update({ questions }, "l2");
                    }}
                    readonly={readonly}
                />
                {l2.enableScore && (
                    <ScoreBands
                        criteria={l2.criteria}
                        onChange={(criteria) => {
                            update({ criteria }, "l2");
                        }}
                        readonly={readonly}
                    />
                )}
            </Page.Body>
        </Page>
    );
});

export default SLR;
