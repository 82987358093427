import { intersection } from "lodash";
import { action, computed } from "mobx";
import http from "@app/lib/http";
import { events } from "@app/lib/store";
import { localStore } from "@app/lib/storage";
import { ws } from "@app/lib/socket";
import BaseStore from "./base";
import config from "@app/config";
import { Role } from "@app/constants";
import User from "@app/state/model/user";
import notify from "@app/components/notify";

/**
 * Default value for the anonymous user
 */
const anonymous = new User({
    fullName: "Anonymous",
    roles: ["anonymous"],
    termsAccepted: true,
});

/**
 * Session related state management class. Provides data related to
 * the current authenticated user as well as methods for authenticating
 * the users.
 */
export class Session extends BaseStore {
    signOut = false;

    /**
     * observable store data
     */
    observable() {
        return {
            redirect: null,
            loading: false,

            checked: false,
            loggedIn: false,

            user: anonymous,
            mfaPending: false,
            email: false,

            reloginLoading: false,
        };
    }

    constructor() {
        super();

        /**
         * Handle user change events and reload the session if the
         * changed user was ours
         */
        const onUserChange = (user) => {
            if (user._id === this.user._id) {
                this.check();
            }
        };

        // handle user events
        events.on("user.update", onUserChange);
        events.on("user.delete", onUserChange);

        // handle user events
        events.on("auth.login", this.wsOpen.bind(this));
        events.on("auth.logout", this.wsClose.bind(this));
    }

    /**
     * Perform a session check against the backend and set the
     * checked flag of the store
     */
    @action
    async check() {
        this.loading = true;

        try {
            let { data } = await http.get("/profile");
            this.user = new User(data);
            this.email = data.email;

            this.loggedIn = data.loggedIn ?? false;
            this.mfaPending = data.mfaPending ?? false;

            if (this.loggedIn) {
                events.emit("auth.login", this.user);
            }
        } catch (ex) {
            // handle unauthorized access and try to auto-login
            if (ex.status === 401) {
                await this.autoLogin();
            } else {
                console.error(ex);
            }
        }

        this.checked = true;
        this.loading = false;
    }

    /**
     * Perform an authentication api call
     */
    @action
    async login(args) {
        try {
            const { data } = await http.post("/auth", args);
            this.email = args.email;

            this.user = new User(data);

            if (data.mfaPending) {
                this.loggedIn = false;
                this.mfaPending = true;
            } else {
                this.loggedIn = true;
                this.mfaPending = false;
            }

            return true;
        } catch (ex) {
            ex.response?.data?.error && notify.error(ex.response.data.error);
            if (ex.response?.data?.type === "LimitExceeded") {
                return null; // To hide the Incorrect Credentials message
            }

            this.loggedIn = false;
            this.mfaPending = false;
        }
    }

    @action setReloginLoading(value) {
        this.reloginLoading = value;
    }

    @action
    async loginOkta(args) {
        try {
            if (args?.relogin) {
                const url = "/api/auth/okta/start?close=true";
                const page = window.open(url, "_blank", "width=600,height=700,popup=true");

                const interval = setInterval(() => {
                    if (page?.closed) {
                        clearInterval(interval);
                        this.check();
                        this.setReloginLoading(false);
                    }
                }, 1000);

                return;
            }
            window.location.replace("/api/auth/okta/start");
        } catch (ex) {
            ex.response?.data?.error && notify.error(ex.response.data.error);
            if (ex.response?.data?.type === "LimitExceeded") {
                return null; // To hide the Incorrect Credentials message
            }

            this.loggedIn = false;
            this.mfaPending = false;
        }
    }

    /**
     * Validate TOTP
     */
    @action
    async validateTOTP(args) {
        try {
            const { data } = await http.post(`/auth/mfa`, { ...args });

            // this.user = new User(data);
            this.mfaPending = false;
            this.loggedIn = true;

            if (data.token) {
                localStore.set("auth.token", data.token);
            }

            await this.check();
            events.emit("auth.login", this.user);
            return true;
        } catch (ex) {
            return false;
        }
    }

    /**
     * Creates a TOTP
     */
    @action
    async generateTOTP() {
        await http.put(`/auth/mfa`);
        notify.success("New verification code successfully sent");
    }

    /**
     * Try to auto login the user using the auth token
     */
    @action
    async autoLogin() {
        let token = localStore.get("auth.token");
        if (token) {
            await this.login({ token: token });
        }
    }

    /**
     * Perform a logout api call
     */
    @action
    async logout() {
        this.signOut = true;

        this.user = anonymous;
        this.loggedIn = false;
        this.mfaPending = false;

        localStore.remove("auth.token");
        await http.delete("/auth");

        events.emit("auth.logout");
    }

    /**
     * Send an email with link to reset the password
     */
    async passwd(args) {
        try {
            await http.get("/auth/passwd", args);
            return true;
        } catch (ex) {
            if (ex.response?.data?.type === "LimitExceeded") {
                notify.error(ex.response?.data.error);
                return null;
            }
            return false;
        }
    }

    /**
     * Send an email with link to reset the password
     */
    async signUp(args) {
        try {
            let { data } = await http.put("/auth/signup", args);
            return data.success;
        } catch (ex) {
            return false;
        }
    }

    /**
     * Verify authorization token
     */
    async verifyToken(args) {
        try {
            let { data } = await http.get("/auth/verify", args);
            return data.authorized;
        } catch (ex) {
            return false;
        }
    }

    /**
     * Update the password
     */
    async updatePassword(args) {
        try {
            let { data } = await http.put("/auth/passwd", args);
            return data.success;
        } catch (ex) {
            return false;
        }
    }

    @computed get isProjectManager() {
        return this.user.roles.includes(Role.PROJECT_MANAGER);
    }

    @computed get isAdmin() {
        return this.can("system.admin");
    }

    @computed get isSSO() {
        return this.user.origin === "sso";
    }

    @computed get isReloginLoading() {
        return this.reloginLoading;
    }

    /**
     * Check if user has access to specific feature
     */
    can(feature) {
        var roles = this.user.roles;

        var allowed = config.acl[feature];
        if (!allowed) {
            throw new Error(`Invalid feature ${feature} in acl check`);
        }

        return intersection(roles, allowed).length > 0;
    }

    /**
     * Check if user belongs to a specific role
     */
    is(role) {
        return this.user.roles.includes(role);
    }

    /**
     * Open the web socket connection when user authenticates
     */
    wsOpen() {
        let url = config.backend + "/ws";
        ws.connect(url);
    }

    /**
     * Close the web socket connection when user authenticates
     */
    wsClose() {
        ws.disconnect();
    }
}

const session = new Session();
export default session;
